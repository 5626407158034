<template>
    <div class="row align-items-center h-100 tracker-show">
        <div class="col-12">
            <div v-if="tracker != null" class="tracker-infos text-center">
                <img class="d-none d-sm-inline w-75" :src="tracker | image_device_type" />

                <div class="tracker-name">
                    {{ tracker.name }}
                </div>

                <SubscriptionRemainingDays :tracker="tracker" class="d-block d-sm-none" />

                <img class="d-inline d-sm-none tracker-img-width" :src="tracker | image_device_type" />

                <div class="tracker-activity">
                    {{ tracker | format_device_type }} <span v-if="new Date(tracker.tracker_status.begin_date) > new Date('01/01/2017') "> {{$t('online_since')}} {{ tracker.tracker_status.begin_date | formatDateDDMMYYYY }} </span>
                </div>
                <div class="tracker-serial">
                    {{$t('serial_number')}} : {{ tracker.serial }}
                </div>
                
                <router-link class="d-block d-sm-none" to="/SubscriptionSelector">
                    <b-button class="sub-button" block variant="primary" v-on:click="setSelectedDevice(tracker)">{{$t('renew')}}</b-button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import SubscriptionRemainingDays from '@/components/subscription/SubscriptionRemainingDays'

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "TrackerShow",
    components : {
        SubscriptionRemainingDays,
    },

    props : {
        tracker: null,
    },

    methods: {
        ...mapActions('devices', ['setSelectedDevice']),
    }
}
</script>

<style lang="scss" scoped>
.tracker-show {
    width: 100vw;

    @media screen and (min-width: 768px) {
        width: auto;
    }
}

.tracker-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
}

.tracker-activity {
    font-size: 15px;
    color: #6c757d;
    margin: 15px 25px;
}

.tracker-serial {
    font-size: 10px;
    color: #6c757d;
    margin: 10px 20px;
}

.tracker-img-width { 
    width: 25vh !important;
}
</style>