<template>
  <div class="carousel h-100">
    <ul class="h-100" :style="listLength">
      <li class="container-fluid h-100" v-for="item in items" :key="item.id" :style="listPosition">
        <slot :item="item" />
      </li>
    </ul>
    <ol class="carousel-indicators">
      <li v-for="(item, index) in items" :key="item.id"  :class="index == currentIndex ? 'active' : ''">
      </li>
    </ol>
  </div>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault'

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name : "ItemsCarousel",
    props : {
        items: null,
    },
    data: () => {
      return {
        touch: {
          startX: 0,
          endX: 0
        }
      }
    },

    computed: {
      ...mapGetters('carousel', ['currentIndex']),
      listLength() {
          return { width: this.items.length * 100 + '%' };
      },
      listPosition() {
          return { transform: 'translateX(-'+ this.currentIndex * 100 +'%)' };
      },
    },

    methods: {
      ...mapActions('carousel', ['nextIndex']),
      ...mapActions('carousel', ['prevIndex']),
      touchstart(event) {
          this.touch.startX = event.touches[0].clientX;
          this.touch.endX = 0;
      },
      touchmove(event) {
          this.touch.endX = event.touches[0].clientX;
      },
      touchend() {
          if(!this.touch.endX || Math.abs(this.touch.endX - this.touch.startX) < 20)
              return;
          if(this.touch.endX < this.touch.startX)
              this.nextIndex(this.items.length);
          else
              this.prevIndex();
      },
    },

    mounted() {
      this.$el.addEventListener('touchstart', event => this.touchstart(event));
      this.$el.addEventListener('touchmove', event => this.touchmove(event));
      this.$el.addEventListener('touchend', () => this.touchend());
    }
  }
</script>

<style lang="scss" scoped>
  .carousel {
    height: 100vh;
    margin: 0 0px;
    ul {
      display: flex;
      list-style: none;
    }
    li {
      transition: all .5s ease;
    }
  }
  .carousel-indicators {
    margin-bottom: 2vh;
    li {
      background-color: #6c757d;
      border-radius: 50%;
      width: 10px;
      height: 10px;
    }
  }
</style>