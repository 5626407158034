<template>
  <div v-if="!isMobile" class="container-fluid h-100">
    <div v-if="getSelectedDevice" class="row align-items-center h-100">
      <div class="col-md-4 h-100">
        
        <TrackerShow :tracker="getSelectedDevice" />

      </div>
      <div class="col-md-8 h-100 bg-lightgrey">

        <SubscriptionSelector />
        
      </div>
    </div>
  </div>
  <ItemsCarousel v-else :items="getDevices">
    <template v-slot="{ item }">
          <TrackerShow :tracker="item" />
    </template>
  </ItemsCarousel>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault'
import LayoutSideBar from '@/layouts/LayoutSideBar'
import ItemsCarousel from '@/components/ui/ItemsCarousel'
import TrackerShow from '@/components/tracker/TrackerShow'
import SubscriptionSelector from '@/components/subscription/SubscriptionSelector'

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: 'SubscriptionPage',
  components: {
    TrackerShow,
    ItemsCarousel,
    SubscriptionSelector,
  },
  data() {
    return {
    }
  },

  methods: {
    ...mapActions('devices', ['fetchDevices']),
    ...mapActions('subscriptions', ['fetchSubscriptions']),
    ...mapActions('users', ['setIsWebView']),
    toast(variant, title, body) {
        this.$bvToast.toast(body, {
          title: title,
          toaster: 'b-toaster-top-right',
          solid: true,
          variant: variant,
        })
      }
  },

  computed: {
    ...mapGetters('devices', ['getSelectedDevice', 'getDevices']),
    ...mapGetters('subscriptions', ['getSubscriptions']),
    ...mapGetters('users', ['isWebView']),
    isMobile() {
      return(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
  },

  created() {
    let parameters = this.$route.query;
    if (parameters['webview']) {
        this.setIsWebView(parameters['webview'] == 'true');
    }
  },

  async mounted() {
    this.$emit('update:layout', this.isWebView ? LayoutDefault : LayoutSideBar);
    let parameters = this.$route.query;
    if (parameters['reason'] == 'success') {
      this.toast('success', app.$t('payment_made'), app.$t('payment_message'));
    }
    if ( this.getDevices.length === 0 || parameters['reason'] == 'success' ) {
        await this.fetchDevices();
    }
    if ( this.getSubscriptions.length === 0) {
        await this.fetchSubscriptions();
    }
  },
}
</script>

<style scoped>
.bg-lightgrey{ 
  background-color : rgb(247, 241, 241, 0.3);
}
</style>